import * as React from "react";
import { useCallback, useRef } from "react";
import { Animated, Pressable, TextStyle, View, ViewStyle } from "react-native";
import { Tooltip } from "react-native-popper";
import { IPopoverProps } from "react-native-popper/lib/typescript/types";

import { tailwind } from "../styles";
import Caption from "../ui/Caption";

export type CarbonTooltipProps = {
  children: JSX.Element;
  tooltipContent: string | JSX.Element;
  tooltipContainerStyle?: ViewStyle;
  tooltipTextStyle?: TextStyle;
  hideArrow?: boolean;
  arrowStyle?: ViewStyle;
  triggerStyle?: ViewStyle;
  delay?: number;
} & Partial<IPopoverProps>;

const CarbonTooltip: React.FC<CarbonTooltipProps> = ({
  children,
  tooltipContent,
  tooltipContainerStyle,
  tooltipTextStyle,
  hideArrow,
  arrowStyle,
  triggerStyle = tailwind("self-start"),
  delay = 0,
  ...popoverProps
}) => {
  const fadeAnim = useRef(new Animated.Value(0)).current;
  const prevIsOpen = useRef(false);
  const handleTooltipOpenChange = useCallback(
    (isOpen) => {
      if (delay === 0) {
        return;
      }
      // opened
      if (!prevIsOpen.current && isOpen) {
        Animated.sequence([
          Animated.delay(delay),
          Animated.timing(fadeAnim, {
            toValue: 1,
            duration: 10,
            useNativeDriver: false,
          }),
        ]).start();
        prevIsOpen.current = isOpen;
        return;
      }

      //closed
      if (prevIsOpen.current && !isOpen) {
        Animated.timing(fadeAnim, {
          toValue: 0,
          duration: 10,
          useNativeDriver: false,
        }).start();
        prevIsOpen.current = isOpen;
        return;
      }
    },
    [delay]
  );

  return (
    // TODO: update the parent component to be Tooltip instead of Popover in Enterprise
    <Tooltip
      on="hover"
      placement="top left"
      offset={4}
      trigger={<Pressable style={triggerStyle}>{children}</Pressable>}
      onOpenChange={handleTooltipOpenChange}
      {...popoverProps}
    >
      <Animated.View
        style={{
          opacity: delay === 0 ? 1 : fadeAnim,
        }}
      >
        <Tooltip.Content>
          {!hideArrow && (
            <Tooltip.Arrow
              style={arrowStyle ?? tailwind("bg-gray-600 border-gray-600")}
              width={10}
              height={10}
            />
          )}
          <View style={[tailwind("bg-gray-600 rounded"), tooltipContainerStyle]}>
            {typeof tooltipContent === "string" ? (
              <Caption level={2} style={[tailwind("text-white px-3 py-2"), tooltipTextStyle]}>
                {tooltipContent}
              </Caption>
            ) : (
              tooltipContent
            )}
          </View>
        </Tooltip.Content>
      </Animated.View>
    </Tooltip>
  );
};

export default CarbonTooltip;
