import { makeVar } from "@apollo/client";
import { TypePolicies } from "@apollo/client/cache";
import isNil from "lodash/isNil";

import { isAGeneratedEmailAddress } from "src/bills/utils/isAGeneratedEmailAddress";
import { maskDate, maskEmail, maskPhoneNumber, maskString } from "src/common/utils/maskPhiData";

export const isDemoModeKey = "isDemoMode";
export const TRUE = "true";

export const isDemoMode = makeVar(false);

/**
 * Demo mode is a special mode that allows the user to use the app without
 * displaying patient phi data. It is used for demo purposes.
 *
 * Demo mode is activated by setting the isDemoMode key in localStorage to "true".
 * if the key is not set, demo mode is not activated. This is done to avoid
 * having to validate the stored value.
 */
if (typeof window !== "undefined") {
  isDemoMode(localStorage.getItem(isDemoModeKey) === TRUE);
}

function activateDemoMode(): void {
  isDemoMode(true);
  localStorage.setItem(isDemoModeKey, TRUE);
}

function deactivateDemoMode(): void {
  isDemoMode(false);
  localStorage.removeItem(isDemoModeKey);
}

export function setPersistentDemoMode(newDemoMode: boolean): void {
  newDemoMode ? activateDemoMode() : deactivateDemoMode();
}

export const demoModeTypePolicies: TypePolicies = {
  InsuranceSubscriber: {
    fields: {
      firstName(name) {
        return isDemoMode() ? maskString(name) : name;
      },
      lastName(name) {
        return isDemoMode() ? maskString(name) : name;
      },
    },
  },

  Patient: {
    fields: {
      firstName(name) {
        return isDemoMode() ? maskString(name) : name;
      },
      lastName(name) {
        return isDemoMode() ? maskString(name) : name;
      },
      email(email) {
        return isDemoMode() && !isAGeneratedEmailAddress(email) ? maskEmail(email) : email;
      },
      preferredName(name) {
        return isDemoMode() ? maskString(name) : name;
      },
      phoneNumber(phoneNumber) {
        return isDemoMode() ? maskPhoneNumber(phoneNumber) : phoneNumber;
      },
      birthDetailedDate(date) {
        if (isNil(date)) {
          return date;
        }

        const key = Object.keys(date).find((key) => {
          return key.startsWith("formatted(");
        });

        if (key === undefined) {
          return date;
        }

        return isDemoMode() ? { ...date, [key]: maskDate(date[key]) } : date;
      },
    },
  },

  Address: {
    fields: {
      aptNumber(aptNumber) {
        return isDemoMode() ? maskString(aptNumber) : aptNumber;
      },
      street(street) {
        return isDemoMode() ? maskString(street) : street;
      },
      street2(street) {
        return isDemoMode() ? maskString(street) : street;
      },
    },
  },
};
